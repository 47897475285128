import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";

export default (props) => {
  const { ref } = usePlacesWidget({
    onPlaceSelected: (place) => {   
      props.updateAddressObj(place);
      props.setShowError(false);      
    },
    options: {
      types: ['(regions)'],
      componentRestrictions: { country: "gb" },
      fields:["address_components", "geometry","name","place_id","reference"],
    },
    sessionToken: true,
  });  
  return  <div>
            <input id="search" ref={ref}  className="form-control" placeholder="Search by Area or Postcode" autoComplete="off" onChange={() => {
                props.updateAddressObj([]);
                props.setShowError(false);              
            }} />
            { props.showError && <span class="mt-2 mb-2 selectionError">Start typing an area or postcode and select from the list</span> }
      </div>
  ;
};
