import * as qs from "query-string"
import axios from 'axios';


export const youtube_parser =(url) => {
  var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  var match = url.match(regExp);
  return (match && match[1].length==11)? match[1] : false;
}

export const getOffset = ( el ) => {
  let _x = 0;
  let _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
  }
  return { top: _y, left: _x };
}


export const addCommas = num => num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";

export  const removeNonNumeric = num => num ? num.toString().replace(/[^0-9]+/g, "") : "";

export  const numericWithDecimal = num => num ? num.toString().replace(/[^0-9\.]+/g, "") : "";

export const numberOnly = (value) => {
  if(value){
    if(!/^[0-9]+$/.test(value)){
      //alert("Please only enter numeric characters.")
      return "Please enter  only numeric characters.";
    }
  }  
}

export const externalURL = (link) => {
  if (link && (link.indexOf("http://") === 0 || link.indexOf("https://") === 0)) {
      return true;
  }
  else{
    return false;
  }
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export  const phoneNumberValidation = (num) =>  {
  if(num){
    return num.toString().replace(/[^0-9\+ ]+/g, "")
  } else {
    return false;
  } 
}

export const randomArrayShuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

