import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col, Form, Button  } from "react-bootstrap"
import { filterNumber, numberFormat, pmt } from "./mortgagecalc/util"

const HowMuchBorrow = (props) =>{

    const prefix = "£"
    const [validated, setValidated] = useState(false)
    const [showerror, setShowerror] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [showsalarytwodiv, setSalaryTwoDiv] = useState(false)
    const [showresult, setShowResult] = useState(false)
    const [borrowers, setBorrowers] = useState('1')
    const [salaryone, setSalaryOne] = useState(
      prefix + numberFormat(10000)
    )
    const [salarytwo, setSalaryTwo] = useState(
      prefix + numberFormat(0)
    )
    const [result, setResult] = useState("")

    const handleSalaryOne = event => {
        let val = filterNumber(event.target.value)
        setSalaryOne(prefix + numberFormat(val))
    }

    const handleSalaryTwo = event => {
        let val_two = filterNumber(event.target.value)
        setSalaryTwo(prefix + numberFormat(val_two))
    }

    
    const handlePropertyType = event => {
        let val = filterNumber(event.target.value)
        setBorrowers(numberFormat(val))
        if(val == 2) {
            setSalaryTwoDiv(true)
        }
        else {
            setSalaryTwoDiv(false)
            setSalaryTwo(prefix + numberFormat(0))
        }
    }

    
    const getResult = (salaryone, salarytwo, borrowers) => {
        let result = (filterNumber(salaryone) * 4.50) + (filterNumber(salarytwo) * 4.50)
        setResult(Math.round(result))
    }
    
    const handleSubmit = event => {
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()
        if (form.checkValidity() === false) {
            setShowerror(true)
            setValidated(true)
            setShowResult(false)
        }
        else {

            if(borrowers == 2){
                if(filterNumber(salaryone) == 0 && filterNumber(salarytwo) == 0) {
                    setShowerror(true)
                    setShowResult(false)
                    setErrorText('* Please enter Salary 1 & 2 value')
                }else{
                    if(filterNumber(salaryone) == 0 || filterNumber(salarytwo) == 0) {

                        if(filterNumber(salaryone) == 0) {
                            setErrorText('* Please enter Salary 1 value')
                        }

                        if(filterNumber(salarytwo) == 0) {
                            setErrorText('* Please enter Salary  2 value')
                        }
                        setShowerror(true)
                        setShowResult(false)
                        
                    }else{
                        setShowResult(true)
                        setShowerror(false)
                        setValidated(false)
                        getResult(salaryone,salarytwo, borrowers)
                    }
                }
            }else{
                if(filterNumber(salaryone) == 0) {
                    setShowResult(false)
                    setShowerror(true)
                    setErrorText('* Please enter Salary 1 value')
                }
                else {
                    setShowResult(true)
                    setShowerror(false)
                    setValidated(false)
                    getResult(salaryone,salarytwo, borrowers)
                }
            }


           
        }
    }

    
  useEffect(() => {
      getResult(salaryone, salarytwo, borrowers)
  }, [])

    return (
        <div className="calculator">
        {showerror && (
          <div className="alert-danger">
            <p>{errorText}</p>
          </div>
        )}
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
            >
                <Form.Group>
                    <Form.Label>No. of borrowers*</Form.Label>
                    <Form.Control 
                    as="select"
                    className="form-control form-select"
                    required
                    onChange={handlePropertyType}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="salary_one">
                    <Form.Label>Salary 1*</Form.Label>
                    <Form.Control 
                        required
                        type="text"
                        name="salary_1"
                        placeholder="£"
                        value={salaryone}
                        onChange={handleSalaryOne}
                    />
                </Form.Group>
                {showsalarytwodiv &&
                <Form.Group controlId="salary_two">
                    <Form.Label>Salary 2*</Form.Label>
                    <Form.Control 
                        required
                        type="text"
                        name="salary_2"
                        placeholder="£"
                        value={salarytwo}
                        onChange={handleSalaryTwo}
                    />
                </Form.Group>
                }

                <Button type="submit" className="btn btn-secondary">
                    Submit
                </Button>
            </Form>
            {showresult &&
            <div className="result">
                Amount you could borrow : <span>£ {numberFormat(result)}</span>
            </div>
            }
        </div>
    )
}

export default HowMuchBorrow;
