import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import parse from "html-react-parser"
import BookView from "../forms/book-a-viewing-form-global"

const BookViewing = (props) => {
    return (
        <React.Fragment>
            <section className="arrange-a-viewing-page">
                <div className="page">
                    <Container>
                        <Row>
                            <Col lg={2}>
                            </Col>
                            <Col md='12' lg={8}>
                                <div className="main-form">
                                    <h2>Arrange a viewing</h2>
                                    <BookView />
                                </div>
                            </Col>
                            <Col lg={2}>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment >
    )
}

export default BookViewing