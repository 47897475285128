import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import parse from "html-react-parser"
import "../Valuation/ValuationForm/ValuationForm.scss"

import Registration from "../forms/register-form"

const ContactLanding = (props) => {
    return (
        <React.Fragment>
          <section className="valuation-page" style={{background:"url(" + props.page.Banner_Image.url + ")"}}>
            <div className="page">
              <Container>
                <Row>
                  <Col md='12' lg={7}>
                    {parse(props.page.Banner_Content)}
                  </Col>
                </Row>
                <Row>
                  <Col md='12' lg={8}>
                    <div className="valuation-form contact-main-form">
                        <div className="main-calc">
                            <div className="form-wrapper">
                                <Registration />
                            </div>
                        </div>
                    </div>
              </Col>
              <Col lg={4} className="d-none d-lg-block">
                
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment >
    )
}

export default ContactLanding