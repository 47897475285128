import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import parse from "html-react-parser"


const DisclaimerBlock = (props) => {
    return (
        <React.Fragment>
            <div className="diclaimer-module">
                <Container>
                    <Row>
                        <Col md={12}>
                            {props.Add_Disclaimer_Content &&
                                <div className="disclaimer">
                                    {parse(props.Add_Disclaimer_Content)}
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DisclaimerBlock