import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import ValuationForm from './ValuationForm/ValuationLanding'
import "./Valuation.scss"
// markup
const Valuation = (props ) => {
  return ( 
        <React.Fragment>
          <ValuationForm module={props.module} page={props.page} />
        </React.Fragment>
    )
}
export default Valuation