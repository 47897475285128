import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
import GenerateLink from "../../common/site/generate-link"
import VirtualTourPlay from "../../Play/CustomVideo"

import PlayVideo from '../../../components/Play/PlayVideo';
import ContactModal from "../../ModalForm/Contact"
import "./About.scss"

import { inViewOptions, containerVariants, contentItem, fadeInFromTop, titleVariants, contentVariants } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const MultiContent = (props) => {
    const [isPlay, setPlay] = useState(false);
    return (
        <InView  {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className={`about-section components--Home--About--About with-image multi-column-content-section`}
                    ref={ref}
                    initial="visible"
                    animate={"visible"}
                    variants={containerVariants}

                >
                    <Container>
                        <Row>
                            <div className="head about-content">
                                {parse(props.Section_Intro)}
                            </div>
                        </Row>
                    </Container>

                    <Container>
                        {props.Add_New_Column && props.Add_New_Column.map((item, index) => {
                            var align = "right"
                                if (index % 2) {
                                    align = "right"
                                }
                                else {
                                    align = "left"
                                }
                            
                            return (
                                <React.Fragment>
                                {isMobile ?
                                <Row className="main-section">
                                    <Col md="12" lg="6" xl="6">
                                        <motion.div variants={titleVariants} className="image-video">
                                            {/* <Img fluid={item.Image.url_sharp.childImageSharp.fluid} alt={item.Image.alternativeText} /> */}
                                            <GatsbyImage image={getImage(item.Image.url_sharp)} alt={item.Image.alternativeText} />
                                            {item.Video_URL && <VirtualTourPlay url={item.Video_URL} />}
                                        </motion.div>
                                    </Col>
                                    <Col md="12" lg="6" xl="6">
                                        <motion.div variants={contentVariants} className={`about-content ${align == "right" ? 'left-content' : ''}`}>
                                            {item.Content &&
                                                <div className="main-content">
                                                    {parse(item.Content)}
                                                </div>
                                            }

                                            {props.Intro_CTA_Label && props.Intro_CTA_Link &&
                                                <GenerateLink link={props.Intro_CTA_Link} class="btn">
                                                    <span>{props.Intro_CTA_Label}</span>
                                                    <i className="icon-arrow"></i>
                                                </GenerateLink>
                                            }
                                            {props.Intro_CTA_Label && props.Intro_CTA_Form === "Contact_Form" &&
                                                <ContactModal class="btn"> {props.Intro_CTA_Label}<i className="icon-arrow"></i></ContactModal>
                                            }

                                        </motion.div>
                                    </Col>
                                </Row>
                                :
                                <Row className="main-section">
                                    {item.Image && align == "left" &&
                                        <Col md="12" lg="6" xl="6">
                                            <motion.div variants={titleVariants} className="image-video">
                                                <GatsbyImage image={getImage(item.Image.url_sharp)} alt={item.Image.alternativeText} />
                                                {/* <Img fluid={item.Image.url_sharp.childImageSharp.fluid} alt={item.Image.alternativeText} /> */}
                                                {item.Video_URL && <VirtualTourPlay url={item.Video_URL} />}
                                            </motion.div>
                                        </Col>
                                    }
                                    <Col md="12" lg="6" xl="6">
                                        <motion.div variants={contentVariants} className={`about-content ${align == "right" ? 'left-content' : ''}`}>
                                            {item.Content &&
                                                <div className="main-content">
                                                    {parse(item.Content)}
                                                </div>
                                            }

                                            {props.Intro_CTA_Label && props.Intro_CTA_Link &&
                                                <GenerateLink link={props.Intro_CTA_Link} class="btn">
                                                    <span>{props.Intro_CTA_Label}</span>
                                                    <i className="icon-arrow"></i>
                                                </GenerateLink>
                                            }
                                            {props.Intro_CTA_Label && props.Intro_CTA_Form === "Contact_Form" &&
                                                <ContactModal class="btn"> {props.Intro_CTA_Label}<i className="icon-arrow"></i></ContactModal>
                                            }

                                        </motion.div>
                                    </Col>
                                    {item.Image && align == "right" &&
                                        <Col md="12" lg="6" xl="6">
                                            <motion.div variants={titleVariants} className="image-video">
                                                <GatsbyImage image={getImage(item.Image.url_sharp)} alt={item.Image.alternativeText} />
                                                {/* <Img fluid={item.Image.url_sharp.childImageSharp.fluid} alt={item.Image.alternativeText} /> */}
                                                {item.Video_URL && <VirtualTourPlay url={item.Video_URL} />}
                                            </motion.div>
                                        </Col>
                                    }
                                </Row>
                                }
                                </React.Fragment>
                            )
                        })}
                    </Container>
                </motion.section>
            )}
        </InView>

    )
}
export default MultiContent
