import { Link } from "gatsby"
import * as React from "react"
import parse from "html-react-parser";
import { Container, Row, Col } from 'react-bootstrap';
import GenerateLink from "../common/site/generate-link"
import Enquiry from "../Branches/Enquiry";
import BranchEnquiry from "../Branches/BranchEnquiry";
import InvestorQuestion from "../Branches/InvestorQuestion";
const StaticContent = (props) => {


    return (
        <div className={`video-block-wrap plain-static-content ${props.style == 'Full_Width' ? 'full-content' : ''} ${props.count === 0 && 'top-header'} components--StaticContent--StaticContent`}>
            <Container>
                <Row>
                    <Col lg={12} className="block-content block-text">
                        <div className="content slide-above">
                            {props.Add_Static_Content.includes('src="https://www.videoask.com/') ?
                            <>{parse(props.Add_Static_Content.replace("[branch-contact-form]", '').replace('></iframe>','height="400px" allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"></iframe>'))}</> : <>{parse(props.Add_Static_Content.replace("[branch-contact-form]", ''))}</> }
                            {props.Add_Static_Content.includes("[branch-contact-form]") &&
                                <p>We’ve always got an expert local to you with high-street branches across the Isle of Wight. For general enquiries, contact us on <a href="tel:08000932274" class="phone-link">0800 093 2274</a> or fill in our  <BranchEnquiry name={`enquiry`} branch={'Branch Contact'} class="mail">contact form</BranchEnquiry>.</p>
                            }
                            {props.Show_Search_In_Sidebar == false && props.Sidebar_Custom_CTA_Label && props.Sidebar_Custom_CTA_Link &&
                                <React.Fragment>
                                    {props.Sidebar_Custom_CTA_Link.URL === "complaint-popup-form" ?
                                        <Enquiry title="Submit a complaint" branch={'Contact form'} class="btn btn-secondary single-cta" > {props.Sidebar_Custom_CTA_Label} </Enquiry> :
                                        props.Sidebar_Custom_CTA_Link.URL === "investor-question-popup-form" ?
                                            <InvestorQuestion title="Ask Vicki a question about property investment" branch={'Contact form'} class="btn btn-secondary single-cta" > {props.Sidebar_Custom_CTA_Label} </InvestorQuestion> :
                                            <GenerateLink link={props.Sidebar_Custom_CTA_Link} class="btn btn-secondary single-cta">
                                                {props.Sidebar_Custom_CTA_Label}
                                            </GenerateLink>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StaticContent