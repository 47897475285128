import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"


const SelectField = ({ name, required, placeholder, values, handlechange, fieldClass, label, grpmd, componentprops, step, type, id, alias, formtype }) => {
    var sell_select = false
    if (alias == "contact-form-selling") {
        sell_select = true
    }
    else if (formtype == "Selling") {
        sell_select = true
    }
    return (
        <Form.Group as={Col} md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
            {label
                ? <Form.Label className="form-label">{label}{required ? '' : ''}</Form.Label>
                : ''

            }
            <Form.Control
                controlId={id}
                className={fieldClass ? fieldClass + ' form-select' : ' form-select'}
                required={required}
                name={name}
                onChange={handlechange}
                as="select"
            >
                <option value="" key="">{placeholder}</option>
                <option value="Sales">Buying</option>
                <option value="Selling" selected={sell_select}>Selling</option>
                <option value="Lettings" selected={alias == "contact-form-landlords" || formtype == "lettings"}>Lettings</option>
                <option value="Mortgages" selected={alias == "contact-form-mortgages" || formtype == "mortgages"}>Mortgages</option>
                <option value="Conveyancing" selected={alias == "contact-form-conveyancing" || formtype == "conveyancing"}>Conveyancing</option>
                <option value="Over 60s Budget Booster" selected={alias == "contact-form-homeforlifeplan"}>Home For Life Plan</option>
                <option value="Land &amp; New Homes" selected={alias == "contact-form-land-and-new-homes" || formtype == "land-and-new-homes"}>Land &amp; New Homes</option>
                <option value="Home Insurance">Home Insurance</option>
                <option value="Careers">Careers</option>
                <option value="Other">Other</option>
            </Form.Control>
        </Form.Group >

    )
}


export default SelectField;