import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Select from "react-dropdown-select";
import parse from "html-react-parser"
import YieldCalculator from "./calculators/rentalyieldcalc/index"
import StampDutyCalculator from "./calculators/stampdutycalc/index"
import Img01 from "../images/avatar.png"
import ScrollAnimation from 'react-animate-on-scroll';
import "./Valuation/ValuationForm/ValuationForm.scss"
// markup
const CalculatorLanding = (props) => {
  const Departments = [
    { value: "Detached House", label: "Detached House" },
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
  ];
  return (
    <React.Fragment>
      <section className="valuation-page" style={{ background: "url(" + props.page.Banner_Image.url + ")" }}>
        <div className="page">
          <Container>
            <Row>
              <Col md='12' lg={7}>
                {parse(props.page.Banner_Content)}
              </Col>
            </Row>
            <Row>
              <Col md='12' lg={8}>
                <div className="valuation-form">
                  {props.name === "Yield_Calculator" ? <YieldCalculator type="landing" /> : props.name === "Stamp_Duty_Calculator" ? <StampDutyCalculator /> : ''}

                  {props.page.Static_Page_Sidebar_Contact_Person &&
                    <div className="connect-us">
                      <div className="connect-wrapper">
                        {props.page.Static_Page_Sidebar_Contact_Person.Image &&
                          <div className="img">
                            <picture>
                              <img src={Img01} alt=""/>
                            </picture>
                          </div>
                        }

                        <div className="img-details">
                          <h2>Speak to your local property experts today</h2>
                          <div className="contact">
                            <a href="tel:0800 093 2274" className="call"><i className="icon-call"></i></a>
                            <a href="tel:0800 093 2274" className="tel">0800 093 2274</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </Col>
              <Col lg={4} className="d-none d-lg-block">

              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment >
  )
}
export default CalculatorLanding