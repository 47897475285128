import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import parse from 'html-react-parser';
import $ from "jquery"

import "../PropertyDetails/RoomDetails/RoomDetails.scss"

const AccordionModule = (props) => {
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-minus-accord" : "icon-plus-accord"
                    }
                ></i>
            </button>
        )
    }


    const [pagehash, setPageHash] = useState(0)

    useEffect(() => {
        const pageurl = typeof window !== 'undefined' ? window.location.href : ''
        var pageval = pageurl.split('#')[1]
        if(pageval) {
            setPageHash(pageval)
            var a = document.getElementById(pageval)
            if(a) {
                a.getElementsByClassName("icon-plus-accord")[0]?.click()
                $('html, body').animate({
                    scrollTop: $("#"+pageval).offset().top - 200
                }, 100);
            }
        }
    }, [])

    // console.log("test",pagehash)

    return (
      <React.Fragment>
        <section className="room-details faq-section-list full-width-accordion-module components--FAQ--FAQSection">
            <Container>
                {props.Accordion_Intro_Content ?
                <Row className="head">
                    <Col lg={10}>
                        {parse(props.Accordion_Intro_Content)}
                    </Col>
                </Row> : '' }
                <Row className="main-list">
                    <Col lg={12}>
                        <Accordion defaultActiveKey={pagehash}>
                            {props.Add_Accordion && props.Add_Accordion.map((item, i) => (
                            <Card>
                                <div className="accordion-header card-header" id={item.Accordion_Unique_ID}>
                                    <ContextAwareToggle eventKey={item.Accordion_Unique_ID ? item.Accordion_Unique_ID : i+1} className="accordion-header">
                                        <h4>{item.Accordion_Title}</h4>
                                    </ContextAwareToggle>
                                </div>
                                <Accordion.Collapse eventKey={item.Accordion_Unique_ID ? item.Accordion_Unique_ID : i+1}>
                                    <div className="accordion-card accordion-card-first card-body">
                                        <div className="accordion-list">
                                            {parse(item.Accordion_Content)}
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Card>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
                        
                            </section>
      </React.Fragment>
    )
}

export default AccordionModule;
