import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import ModalTiny from "react-bootstrap/Modal"
import _ from "lodash"

import { useWindowSize } from '../../hooks/winodw-size'

import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby";
import "../AreaGuideDetails/News/News.scss"
import MainNews from "../AreaGuideDetails/News/News"
import { NewsDate } from "../common/site/functions"
import PlayVideo from '../Play/CustomVideo';
import FormBlock from '../Home/FormBlock/FormBlock';

// import "./Branches.scss";


const postsPerPage = 16;
let arrayForHoldingPosts = [];


const CaseStudiesLanding = () => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        caseStudies(publicationState: LIVE, sort: "Date:desc") {
            Date
            Title
            Short_Description
            Video_URL
            URL
            Tile_Image {
              url
              alternativeText
            }
            Address
        }

      globalModule {
        Case_Studies_Landing_Page {
          CTA_1_Label
          CTA_2_Label
          Content
          Name
          Show_Chart
          Select_Form {
            Select
          }
          CTA_1_Link {
            id
          }
          CTA_2_Link {
            id
          }
        }
      }

    }
  }
`);

const allnews = data.glstrapi.caseStudies;
// const allcats = data.glstrapi.newsCategories;
const global = data.glstrapi.globalModule.Case_Studies_Landing_Page;
const [category, setCategory] = React.useState("all")
const [ filteredTeam, setFilteredTeam ] = useState([]);
const [postsToShow, setPostsToShow] = useState([]);
const [next, setNext] = useState(16);
const [filterupdate, setFilterUpdate] = React.useState(false)
const [ search_text, setSearchText ] = useState('');
const [ loadtext, setLoadText ] = useState('');
const [catupdate, setCatUpdate] = React.useState(1)

const [ windowWidth ] = useWindowSize();
const [isPlay, setPlay] = useState(false);
const [show, setShow] = useState(false);
const showFilter = () => {
  setShow(!show);
};


const BranchFilter = (url) => {
	setCatUpdate(2);
    setCategory(url)
	setFilterUpdate(true)
	setNext(16)
	setSearchText("");
}

const handlechange = (event) => {
    // remove initial empty spaces
	setCatUpdate(2)
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);
	setFilterUpdate(true)
  }

const filteredList = () => {
	setFilteredTeam([])
	var filteredTeamList = []
	if(catupdate == 2) {
		setCatUpdate(3)
	}
    if(category == "all" && search_text == '') {
        filteredTeamList = allnews
    }
    else {
		if(search_text) {
			for(var i=0; i < allnews.length; i++) {
				var full_name = allnews[i].Title.toLowerCase()
				if(full_name.includes(search_text.toLowerCase()))
				{
					filteredTeamList.push(allnews[i])
				}
			}
        }
        else
        {
            filteredTeamList = allnews.filter(branch => {
            let main_category = branch.Category.filter(cat => _.includes(cat, category));
                return main_category.length > 0
            });
        }
    }
    if(filteredTeamList.length == 0) {
        setLoadText("Sorry, No results found")
    }
	setFilteredTeam(filteredTeamList)
	setFilterUpdate(false)
}

const loopWithSlice = (start, end) => {
    const slicedPosts = filteredTeam.slice(start, end);
    arrayForHoldingPosts = [...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const loopWithSliceMore = (start, end) => {
    const slicedPosts = filteredTeam.slice(start, end);

    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSliceMore(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

useEffect(() => {
    if((catupdate == 1 || catupdate == 2) && (filteredTeam.length == 0 || filterupdate)) {
        filteredList()
    }
    loopWithSlice(0, postsPerPage);
}, [ filteredTeam, category, search_text]);

    return (
        <React.Fragment>
            <section className="similar-property news-main-landing-page case-studies-landing">
                <Container>
                    <Row>
                        
                    <div className="news-wrap">
                <Container>
                    {/* <Row>
                      <Col md="12">
                        <div className="tab-wrap">
                          <button type="button" className={`all-btn ${category === 'all' && "active"}`}  onClick={() => {BranchFilter('all');showFilter();}}>All News <span>{show ? "-" : "+"}</span></button>
                          <div className={show ? "tabing-list d-block" : "tabing-list "} >
                              <ul>
                                {allcats.map((area, index) => (
                                <React.Fragment>
                                {area.all_news.length > 0 &&
                                  <li>
                                    <a href="javascript:void(0);" className={`${category === area.URL && "active"}`} onClick={() => {BranchFilter(area.URL);}}>{area.Name}</a>
                                  </li>
                                }
                                </React.Fragment>
                                ))}
                              </ul>
                          </div>
                            <Form className="news-filter">
                               <i className="icon-search"></i>
                                  {windowWidth < 768 && <Form.Control autoFocus id="search" onChange={handlechange} placeholder="Search..." />}
                                  {windowWidth >= 768 && <Form.Control autoFocus id="search" onChange={handlechange} placeholder="Search news" />}
                            </Form> 
                              
                            </div>
                      </Col>
                    </Row> */}
                </Container>
                    </div>

                    {postsToShow.length == 0 &&
                        <p className="no-results-found">{loadtext}</p>
                    }

                    {postsToShow.slice(0,1).map((item, index) => (
                      <MainNews {...item}/>
                    ))}

                    {postsToShow.length > 1 &&
                      <Row className="news-list">
                        {postsToShow.slice(1,1000).map((node, index) => (
                          <React.Fragment>
                          <Col lg={4}>
                          <ScrollAnimation animateIn="fadeIn" animateOnce>
                            <div className="news-item">
                              <div className="image img-zoom">
                                <Link to={node.URL}>
                                < img src={node.Tile_Image.url} alt={node.Tile_Image.alternativeText} />
                                </Link>
                                {node.Video_URL &&
                                  <PlayVideo url={node.Video_URL} />
                                }
                              </div>
                            <Link to={node.URL}>
                              <div className="content">
                                <p className="title">{node.Title}</p>
                                <p className="date">{node.Address}</p>
                              </div>
                            </Link>
                            </div>
                            </ScrollAnimation>
                          </Col>
                          {index == 2 &&
                            <Col lg={12}>
                              <FormBlock {...global}/>
                            </Col>
                          }
                          </React.Fragment>
                        ))}
                      </Row>

                    }

                    

                {arrayForHoldingPosts.length !== filteredTeam.length ?
                  <div className="area-guide-load-more people-landing-load-more"><button className="btn btn-pagination" onClick={() => handleShowMorePosts()}>Load more</button></div>
                  : ''
                }
                    </Row>
                </Container>
            </section>

        </React.Fragment >
    )
}
export default CaseStudiesLanding
