import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import GenerateLink from "../../common/site/generate-link"

import Mortgage from "../../../images/area-guide/mortgage-color.svg";
import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import "./OurMortgage.scss"
const OurMortgage = (props) => {
    return (
        <InView  {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="our-mortgage components--AreaGuideDetails--OurMortgage--OurMortgage"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={contentItemStagger}

                >
                    <Container>
                        <Row>
                            {props.Add_New_Block && props.Add_New_Block.slice(props.cut ? 0 : 1, 10).map((service, i) => {
                                return (
                                    <Col md="12" lg="6">
                                        <div className="mortgage-content">
                                            <motion.div variants={contentItem} className="img-zoom about-block-img">
                                                {service.Image &&
                                                    <GatsbyImage image={getImage(service.Image.url_sharp)} alt={service.Image.alternativeText} />
                                                    // <Img fluid={service.Image.url_sharp.childImageSharp.fluid} alt={service.Image.alternativeText} />
                                                }
                                            </motion.div>
                                            <div className="about-content">
                                                {service.Title &&
                                                    <span className="sub-title">
                                                        <motion.div variants={contentItem}>{service.Title}
                                                        </motion.div>
                                                    </span>
                                                }
                                                {parse(service.Content)}

                                                {service.CTA_Link &&
                                                    <motion.div variants={contentItem}>
                                                        <GenerateLink link={service.CTA_Link} class="btn">{service.CTA_Label}
                                                            <i className="icon-arrow"></i>
                                                        </GenerateLink>
                                                    </motion.div>
                                                }

                                                {/* <div className="block-flex">
                                                  <Link href="#" className="btn">Speak to us<i className="icon-arrow"></i></Link>
                                                <div className="powerby">
                                                    <span>Powered by</span>
                                                    <img src={Mortgage} alt="" width="108"/>
                                                </div>
                                           </div> */}
                                            </div>



                                        </div>
                                    </Col>
                                )
                            })}


                        </Row>

                    </Container>

                </motion.section>
            )}
        </InView>
    )
}
export default OurMortgage
