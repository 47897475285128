import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import parse from "html-react-parser"
import "../Valuation/ValuationForm/ValuationForm.scss"
import "./EnquiryFormModule.scss"

import EnquiryForm from "../forms/enquiry-form"

const EnquiryFormModule = (props) => {
  console.log('props.modules', props)
  const [alias, setAlias] = useState('');
  const [formtype, setFormType] = useState('');

  useEffect(async () => {
    if (localStorage.getItem('page-alias')) {
      setAlias(localStorage.getItem('page-alias'))
    }
    
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    var pageval = new URL(pageurl)
    var typeval = pageval.searchParams.get("type")
    if(typeval) {
      setFormType(typeval)
    }
  }, []);

  var bg_img = ''
  if(props.Background_Image) {
    bg_img = props.Background_Image?.url_sharp?.childrenImageSharp[0]?.fixed?.srcWebp
  }else if(props.page.Banner_Image) {
    bg_img = props.page.Banner_Image?.url_sharp?.childrenImageSharp[0]?.fixed?.srcWebp
  }

    return (
        <React.Fragment>
          <section className="valuation-page enquiry_form_module" style={{background:"url(" + bg_img + ")"}}>
            <div className="page">
              <Container>
                {props.Header_Content &&
                 <Row>
                  <Col md='12' lg={7} className="header_content">
                    {parse(props.Header_Content)}
                  </Col>
                </Row>
                }
               
                <Row>
                  <Col md='12' lg={8}  className="form_module">
                    <div className="valuation-form contact-main-form">
                        <div className="main-calc">
                            <div className="form-wrapper">
                                <EnquiryForm alias={alias} formtype={formtype} to_email_id={props.To_Email ? props.To_Email : '' }/>
                            </div>
                        </div>
                    </div>
              </Col>
              <Col lg={4} className="d-none d-lg-block">
                
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment >
    )
}

export default EnquiryFormModule