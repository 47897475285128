import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import parse from 'html-react-parser';

import "../PropertyDetails/RoomDetails/RoomDetails.scss"

const FAQSection = (props) => {
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-up" : "icon-down"
                    }
                ></i>
            </button>
        )
    }
    return (
      <React.Fragment>
        <section className="room-details faq-section-list components--FAQ--FAQSection">
            <Container>
                <Row className="head">
                    <Col xs={6} lg={10}>
                        <span class="sub-title">Frequently asked questions</span>
                    </Col>
                    <Col xs={6} lg={2}>
                        <Link class="link-text" to="/frequently-asked-questions">More FAQs<i class="icon-arrow"></i></Link>
                    </Col>
                    
                    <Col lg={12}>
                        <h2>{props.FAQ_Title}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Accordion >
                            {props.Select_FAQs && props.Select_FAQs.map((item, i) => (
                            <Card>
                                <div className="accordion-header card-header">
                                    <ContextAwareToggle eventKey={i+1} className="accordion-header">
                                        <h4>{item.Question}</h4>
                                    </ContextAwareToggle>
                                </div>
                                <Accordion.Collapse eventKey={i+1}>
                                    <div className="accordion-card accordion-card-first card-body">
                                        <div className="accordion-list">
                                            {parse(item.Answer)}
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Card>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
                        
                            </section>
      </React.Fragment>
    )
}

export default FAQSection;
