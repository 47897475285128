import React from "react";
// import ModalVideo from 'react-modal-video'
// import 'react-modal-video/scss/modal-video.scss';
// import 'node_modules/react-modal-video/scss/modal-video.scss';

const PlayVideo = (props)  => {
    // console.log("propssss",props)
    return (
        <React.Fragment>
         {/* <ModalVideo 
            channel='youtube' 
            key={props.key}
            autoplay 
            isOpen={props.isOpen} 
            videoId={props.videoId}
            autoplay={1}
            onClose={ e => props.isCloseFunction(false)}
         /> */}
        </React.Fragment>
    )
};

export default PlayVideo;