import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from "html-react-parser"
import GenerateLink from "../../common/site/generate-link"

import ArunEstates from "../../../images/area-guide/arun-estates-dark.svg";
import ArunBrand from "../../../images/area-guide/ArunBrand-dark.svg";

import Douglas from "../../../images/brand-images/DA-1-Web.svg";
import Pittis from "../../../images/brand-images/PT-Web.svg";
import Cubbit from "../../../images/brand-images/CW-1-Web.svg";
import Wards from "../../../images/brand-images/WK-Web.svg";

import LogoSvg from "../../../images/area-guide/logo-svg-dark.svg";
import Mortgage from "../../../images/footer-brands/MMD-1.svg";
import "./OurBrands.scss"
import { inViewOptions1, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
// markup
const OurBrands = (props) => {


  return (
    <InView  {...inViewOptions1}>
      {({ ref, inView }) => (
        <motion.div
          className="our-brand-section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col md="12">
                <div className="our-brand-heading">
                  <span className="sub-title">{props.Brands_Main_Heading}</span>
                  {parse(props.Brands_Intro)}
                </div>
              </Col>

              {props.Add_Brand && props.Add_Brand.map((item, index) => {
                return (
                  <Col lg="4" className="d-flex mb-space">
                    <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="our-brand-block partner-logo1">
                      <div className="our-brand-logo">
                        {item.Title == "Cubitt & West" ?
                          <img src={Cubbit} alt="" width={item.Title} /> :
                          item.Title == "Douglas Allen" ?
                          <img src={Douglas} alt="" width={item.Title} /> :
                          item.Title == "Pittis" ?
                          <img src={Pittis} alt="" width={item.Title} /> :
                          item.Title == " Wards" ?
                          <img src={Wards} alt="" width={item.Title} /> :
                          item.Title == "Arun Land & New Homes" ?
                          <img src={ArunEstates} alt="" width={item.Title} /> :
                          item.Title == "Mortgage Matters Direct" ?
                          <img src={Mortgage} alt="" width={item.Title} /> :
                          <img src={item.Image.url} alt="" width={item.Title} />
                        }
                      </div>
                      <div className="our-brand-content">
                        <h3>{item.Title}</h3>
                        {parse(item.Content)}
                        <GenerateLink link={item.CTA_Link} class="link-text">
                          <span>{item.CTA_Label}</span><i className="icon-arrow"></i>
                        </GenerateLink>
                      </div>
                    </motion.div>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}
export default OurBrands