import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import parse from 'html-react-parser';
import GenerateLink from "../common/site/generate-link"

import { GetURL } from "../common/site/functions";

const GuidesSection = (props) => {
    
    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
            
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <React.Fragment>
            <section className="guides-section-list">
                <Container>
                    <Row className="head">
                        {props.Guides_Heading &&
                        <Col xs={6} lg={8}>
                            <span class="sub-title">{props.Guides_Heading}</span>
                        </Col>
                        }
                        {props.More_Guides_Link &&
                        <Col xs={6} lg={4}>
                            <GenerateLink link={props.More_Guides_Link?.Details_Page_URL} class="link-text">More Guides<i class="icon-arrow"></i></GenerateLink>
                        </Col>
                        }
                        
                        <Col lg={8}>
                            <h2>{props.Guides_Title}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Slider {...settings} className="guide-tile-slide">
                            {props.Select_Guides_here && props.Select_Guides_here.map((item, i) => {
                                let url = GetURL(item.Category[0]?.Details_Page_URL.id)
                                return (
                                    <Link to={`/${url}/${item.URL}/`}>
                                    <div className="guide">
                                        <div className="img-zoom">
                                            <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} />
                                        </div>
                                        <p className="title"><span>{item.Title}</span></p>
                                    </div>
                                    </Link>
                                )
                            })}
                        </Slider>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default GuidesSection;
