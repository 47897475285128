import React, { useRef, useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { Row, Col, Container } from 'react-bootstrap';
import $ from "jquery"


function myplaceholder(props) {
    return <div>{props.html}</div>;
}

function createMarkup(props) {
    return { __html: (props.html) };
}

function MyComponent(props) {
    return <div dangerouslySetInnerHTML={createMarkup(props)} />;
}
const RadioboxField = ({ fieldClass, name, value, required, placeholder, handlechange, checked, lastchild, step, type, grpmd, label }) => {


    const [amlooking, setAmLooking] = useState('');

    function regChange() {
        var val = $('input[name=iamlookingval]:checked', '#contact-form').val()
        $(".main-radio .radio-checkmark").removeClass("error");
        setAmLooking(val)
        document.getElementById('iamlooking').value = val
    }

    return (
        <>
            <Form.Group as={Col} md={grpmd} className={`applicant-register-custom-radio`} controlId={"validation" + name}>
                <div className="radio-toolbar">
                    <input type="hidden" name="iamlooking" id="iamlooking" />
                    <Row>
                        <Col md={4}>
                            <label class="form-label form-label" for="validationminimum_bedrooms">I'm looking to*</label>
                        </Col>
                        <Col md={2} xs={4}>
                            <div className={`form-group mb-0 main-radio`} onClick={() => regChange()}>
                                <label class="form-label">Buy
                                    <input type="radio" name="iamlookingval" id="buy-reg-radio" value="Buy" required />
                                    <span class="radio-checkmark" for="buy-reg-radio"></span>
                                </label>
                            </div>
                        </Col>
                        <Col md={2} xs={4}>
                            <div className={`form-group mb-0 main-radio`} onClick={() => regChange()}>
                                <label class="form-label">Rent
                                    <input type="radio" name="iamlookingval" id="rent-reg-radio" value="Rent" required />
                                    <span class="radio-checkmark" for="rent-reg-radio"></span>
                                </label>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Form.Group>
            {amlooking == "Buy" ?
                <React.Fragment>
                    <div class="form-group col-md-12">
                        <label class="form-label form-label" for="validationsituation">Your Situation*</label>
                        <select required name="situation" id="validationsituation" class=" form-select form-control">
                            <option value="">Please select any one</option>
                            <option value="FirstTimeBuyer">First time buyer</option>
                            <option value="NotOnTheMarket">Property to sell, not yet on the market</option>
                            <option value="PropertyOnTheMarket">Property on the market</option>
                            <option value="UnderOffer">Under offer</option><option value="NothingToSell">Nothing to sell</option>
                            <option value="Investor">Investor</option>
                        </select>
                    </div>
                    <div class="form-group col-md-12">
                        <label class="form-label form-label" for="validationminimum_bedrooms">Minimum Bedrooms*</label>
                        <select required name="minimum_bedrooms" id="validationminimum_bedrooms" class=" form-select form-control">
                            <option value="">Please select minimum bedrooms</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4+">4+</option>
                        </select>
                    </div>
                    <div class="undefined undefined form-group col-md-12">
                        <label class="form-label form-label" for="validationmaximum_price">Maximum Price*</label>
                        <select required name="maximum_price" id="validationmaximum_price" class=" form-select form-control">
                            <option value="">Please select maximum price</option>
                            <option value="£100,000">£100,000</option>
                            <option value="£125,000">£125,000</option>
                            <option value="£150,000">£150,000</option>
                            <option value="£175,000">£175,000</option>
                            <option value="£200,000">£200,000</option>
                            <option value="£225,000">£225,000</option>
                            <option value="£250,000">£250,000</option>
                            <option value="£275,000">£275,000</option>
                            <option value="£300,000">£300,000</option>
                            <option value="£350,000">£350,000</option>
                            <option value="£400,000">£400,000</option>
                            <option value="£450,000">£450,000</option>
                            <option value="£500,000">£500,000</option>
                            <option value="£600,000">£600,000</option>
                            <option value="£700,000">£700,000</option>
                            <option value="£800,000">£800,000</option>
                            <option value="£900,000">£900,000</option>
                            <option value="£1,000,000">£1,000,000+</option>
                        </select>
                    </div>
                </React.Fragment>
                : amlooking == "Rent" ?
                    <React.Fragment>
                        <div class="form-group col-md-12">
                            <label class="form-label form-label" for="validationminimum_bedrooms">Minimum Bedrooms*</label>
                            <select required name="minimum_bedrooms" id="validationminimum_bedrooms" class=" form-select form-control">
                                <option value="">Please select minimum bedrooms</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4+">4+</option>
                            </select>
                        </div>
                        <div class="undefined undefined form-group col-md-12">
                            <label class="form-label form-label" for="validationmaximum_price">Maximum Price*</label>
                            <select required name="maximum_price" id="validationmaximum_price" class=" form-select form-control">
                                <option value="">Please select maximum price</option>
                                <option value="£1,000">£1,000</option>
                                <option value="£2,000">£2,000</option>
                                <option value="£3,000">£3,000</option>
                                <option value="£4,000">£4,000</option>
                                <option value="£5,000">£5,000</option>
                            </select>
                        </div>
                    </React.Fragment> : ''}
        </>
    )
};

export default RadioboxField;
