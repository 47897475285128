import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, Form } from "react-bootstrap"
import _ from "lodash"
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import VirtualTourPlay from "../Play/CustomVideo"
import "../Home/About/About.scss"

const VirtualTour = () => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        virtualTours(sort: "Sort:asc", publicationState: LIVE) {
          id
          About_Tour
          Sort
          Title
          Virtual_Tour_Link
          Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 700
                  quality: 90
                  layout: CONSTRAINED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                ) 
              }
            }
          }
        }
    }
  }
`);

  const tours = data.glstrapi?.virtualTours;

  return (
    <React.Fragment>
      <section className="about-section components--VirtualTour--VirtualTour pt-0">
        <Container>
          <Row className="virtual-tour-item">
            {tours.map((item, index) => (
              <Col md="4" className={``}>
                <div className="tour">
                  <a href={item.Virtual_Tour_Link} target="_blank">
                    <GatsbyImage image={getImage(item.Image.url_sharp)} alt={item.Image.alternativeText} />
                    {/* <Img fluid={item.Image.url_sharp.childImageSharp.fluid} alt={item.Image.alternativeText} /> */}

                    <button type="button" title="play" className="btn-play">
                      <i className="icon-play"></i>
                    </button>
                  </a>
                  {/* <VirtualTourPlay url={item.Virtual_Tour_Link} /> */}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default VirtualTour;
