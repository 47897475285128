import {Link}  from "gatsby"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";
import { Container, Row, Col } from 'react-bootstrap';
import _ from "lodash"

import GenerateLink from "../common/site/generate-link"

import { inViewOptions1, contentItemStagger, contentItem, titleVariants, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const WhyUsePage = (props) => {

    return (
        <InView  {...inViewOptions1}>
          {({ ref, inView }) => (
            <motion.div
              className="why-use-section benefits"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={contentItemStagger}
              
            >
                <Container>
                    {props.Benefits_Intro &&
                    <motion.div variants={titleVariants}>
                    <Row>
                        <Col lg={10} className="heading">
                        {parse(props.Benefits_Intro)}
                        </Col>
                    </Row>
                    </motion.div>
                    }

                    <Row>
                    {props.Add_Benefit.map((item, index) => (
                        <Col lg={6}>
                            <motion.div key={index} custom={index+1} variants={fadeInFromTop} className="use_block">
                                <div className="img-icon">
                                    {item.Icon === 'Use_Custom_Uploaded_Icon' ? 
                                    <img src={item.or_Icon_Image.url} alt="" /> :
                                    <i className={`icon-${item.Icon === 'Money_Piggy' ? 'sold' : item.Icon === 'Car_Garage' ? 'team-help' : item.Icon === 'Beach_Sunbed' ? 'data' : item.Icon === 'Award_Ribbon' ? 'offices' : item.Icon === 'Question_Network' ? 'exposure' : item.Icon === 'Insurance_Hand' ? 'insurance' : item.Icon === 'Ladder' ? 'ladder' : item.Icon === 'Medical_Files' ? 'medical-files' : item.Icon === 'Calendar_Tree' ? 'calendar-tree' : item.Icon === 'Deal_Shake' ? 'deal-shake' : item.Icon.toLowerCase()}`}></i>
                                    }
                                </div>
                                <div className="content">
                                    {parse(item.Content)}
                                    {item.CTA_Label && item.CTA_Link &&
                                    <GenerateLink link={item.CTA_Link} class="link-text">{item.CTA_Label} <i className="icon-arrow"></i></GenerateLink>
                                    }
                                </div>
                            </motion.div>
                        </Col>
                    ))}
                    </Row>
                    {props.Benefits_Disclaimer &&
                    <Row>
                        <Col lg={10} className="disclaimer">
                        {parse(props.Benefits_Disclaimer)}
                        </Col>
                    </Row>
                    }
                </Container>
          </motion.div>
			)}
		</InView>
    )
}
      
export default WhyUsePage