import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import VirtualTourPlay from "./Play/CustomVideo"

import { inViewOptions2, containerVariants, contentItemStagger, contentItem, fadeInFromTop } from './utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import "./Home/GetKnow/GetKnow.scss";

const CustomerStory = (props) => {

  // 
  const [scrollText, setScrollText] = useState(false);
  // 

  return (
    <React.Fragment>
      <InView  {...inViewOptions2}>
        {({ ref, inView }) => (
          <motion.div
            className={`other-areas-block testimonial-customer-stories components--CustomerStory-CustomerStory pt-0`}
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <InView as="div" threshold={1} onChange={(inView, entry) => {
              console.log('Inview:', inView)
              setScrollText(inView)
            }}>
              <section className={`scroll-text-bg ${props.Stories_Title.replace(/ /g, "-").toLowerCase()}`}>
                <Container>
                  <Row>
                    <Col lg={12}>
                      {/* {props.Stories_Title &&
                      <div className={`block-heading`}>
                        <h2>{props.Stories_Title}</h2>
                      </div>
                    } */}
                      <div className="other-areas-list">
                        <Row>
                          {props.Add_Customer_Story && props.Add_Customer_Story.map((item, index) => (

                            <Col lg={4}>
                              <div className="other-areas-item img-zoom">
                                <div className="other-areas-img">
                                  {item.Image &&
                                    <GatsbyImage image={getImage(item.Image.url_sharp)} alt={item.Image.alternativeText} loading="lazy"/>


                                    // <Img fluid={item.Image.url_sharp.childImageSharp.fluid} alt={item.Image.alternativeText} />
                                  }
                                  <VirtualTourPlay url={item.Video_URL} />

                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            </InView>
          </motion.div>
        )}
      </InView>
      {/* <div className="other-areas-block testimonial-customer-stories components--CustomerStory-CustomerStory"> */}
      {/* </div> */}
    </React.Fragment>
  );
};

export default CustomerStory;
