import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import parse from 'html-react-parser';
import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import GenerateLink from "../../common/site/generate-link"

import { useWindowSize } from '../../../hooks/winodw-size'
import "./About.scss"
import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';
import Recommendation from "../../ModalForm/Recommendation"
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const About = (props) => {
    const [windowWidth] = useWindowSize();
    return (
        <InView  {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className={`about-area components--AreaGuideDetails--About--About about-area-box ${props.service_length == 1 ? "mb-20" : ""}`}
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={contentItemStagger}

                >
                    <Container>
                        <Row>
                            <div className="row-box flex-lg-row-reverse">
                                <Col md="12" lg="6" className="pe-lg-0">
                                    <motion.div variants={contentItem} className="img-zoom about-block-img">
                                        {props.Image &&
                                            <GatsbyImage image={getImage(props.Image.url_sharp)} alt={props.Image.alternativeText} />
                                            // <Img fluid={props.Image.url_sharp.childImageSharp.fluid} alt={props.Image.alternativeText} />
                                        }
                                    </motion.div>
                                </Col>
                                <Col md="12" lg="6">
                                    <div className="about-content">
                                        <span className="sub-title">
                                            <motion.div variants={contentItem}>
                                                {props.Title}
                                            </motion.div>
                                        </span>
                                        <motion.div variants={contentItem}>
                                            {parse(props.Content)}
                                        </motion.div>
                                        {props.CTA_Label && props.CTA_Form === "Recommendation_Form" &&
                                            <motion.div variants={contentItem}>
                                                <Recommendation class="btn"> {props.CTA_Label}<i className="icon-arrow"></i></Recommendation>
                                            </motion.div>
                                        }
                                        {props.CTA_Label && props.CTA_Form === null &&

                                            <motion.div variants={contentItem}>
                                                <GenerateLink link={props.CTA_Link} class="btn">{props.CTA_Label}
                                                    <i className="icon-arrow"></i>
                                                </GenerateLink>
                                            </motion.div>
                                        }

                                    </div>
                                </Col>
                            </div>
                        </Row>


                    </Container>

                </motion.section>
            )}
        </InView>
    )
}
export default About
