import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser"
import Slider from "react-slick";
import $ from "jquery"

import "../Home/GetKnow/GetKnow.scss";
import "../DevelopmentDetails/OurPeople/OurPeople.scss"

import { inViewOptions2, containerVariants, contentItemStagger, contentItem, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const PeopleGlobal = (props) => {


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        peopleGlobal {
            Title
            Add_People {
              Content
              Image {
                url
                alternativeText
              }
              Name
              Position
              
            }
        }
    }
  }
`);

  const history = data.glstrapi.peopleGlobal;


  const settings1 = {
    dots: true,
    speed: 800,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    infinite: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 4000,
        settings: {

          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,

        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 400,
        settings: {

          slidesToShow: 1
        }
      }
    ]
  };

  // 
  const [scrollText, setScrollText] = useState(false);
  // 

  return (
    <InView  {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className={`other-areas-block our-people history-main-slider`}
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <InView as="div" threshold={1} onChange={(inView, entry) => { 
          setScrollText(inView)
          }}>
          <section className={`scroll-text-bg through-the-years ${history.Title.replace(/ /g, "-").toLowerCase()}`}>
            <Container>
              <Row>
                <Col md="12">
                  {/* {history.Title &&
                    <motion.div variants={fadeInFromTop} className={`block-heading`}>
                      <h2>{history.Title}</h2>
                    </motion.div>
                  } */}
                  <div className="team-slider">
                  <div id="block-heading-scroll"></div>
                    <Slider className="banner-imgslider d-block" {...settings1}>
                      {history.Add_People.map((item, index) => (
                        <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="team-item">
                          <Link className="img-zoom">
                            <picture>
                              <img src={item.Image.url} alt="" />
                            </picture>
                          </Link>
                          <div className="team-block">
                            <span className="person-name">{item.Name}</span>
                            {item.Content && <span className="person-profile">{parse(item.Content)}</span> }
                            <span className="person-name">{item.Position}</span> 
                          </div>
                        </motion.div>
                      ))}
                    </Slider>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          </InView>
        </motion.div>
      )}
    </InView>
  )
}
export default PeopleGlobal