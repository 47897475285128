import { Link } from "gatsby"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";
import { Container, Row, Col } from 'react-bootstrap';
import GenerateLink from "../common/site/generate-link"

import { inViewOptions, contentItemStagger, contentItem, titleVariants, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const WhyUse = (props) => {

  const data = useStaticQuery(graphql`
    query {
      glstrapi {  
        globalModule {
          Why_use_Intro_Content
          Why_Use_Services {
            Content
            Icon
            CTA_Label
            CTA_Link {
              id
            }
          }
        }
  
      }
    }
  `);

  const service = data.glstrapi.globalModule;

  return (
    <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="why-use-section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}

        >
          <Container>
            <motion.div variants={titleVariants}>
              <Row>
                <Col lg={8} className="heading">
                  {parse(service.Why_use_Intro_Content)}
                </Col>
              </Row>
            </motion.div>

            <Row>
              {service.Why_Use_Services.map((item, index) => (
                <Col lg={6}>
                  <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="use_block">
                    <div className="img-icon">
                      {item.Icon === 'Use_Custom_Uploaded_Icon' ?
                        <img src={item.or_Icon_Image.url} alt="" /> :
                        <i className={`icon-${item.Icon === 'Money_Piggy' ? 'sold' : item.Icon === 'Car_Garage' ? 'team-help' : item.Icon === 'Beach_Sunbed' ? 'data' : item.Icon === 'Award_Ribbon' ? 'offices' : item.Icon === 'Question_Network' ? 'exposure' : item.Icon === 'Insurance_Hand' ? 'insurance' : item.Icon === 'Ladder' ? 'ladder' : item.Icon === 'Medical_Files' ? 'medical-files' : item.Icon === 'Calendar_Tree' ? 'calendar-tree' : item.Icon === 'Deal_Shake' ? 'deal-shake' : item.Icon.toLowerCase()}`}></i>
                      }
                    </div>
                    <div className="content">
                      {parse(item.Content)}
                      {item.CTA_Label && item.CTA_Link &&
                        <GenerateLink link={item.CTA_Link} class="link-text">{item.CTA_Label} <i className="icon-arrow"></i></GenerateLink>
                      }
                    </div>
                  </motion.div>
                </Col>
              ))}
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default WhyUse