/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Form } from 'react-bootstrap';
import { filterNumber, numberFormat, pmt } from "./util"
import { defaultValues } from "./config"
// use project specific import here
import { calculateMonthlyPayment } from "../mortgage"
import { addCommas, numericWithDecimal, removeNonNumeric } from "../utils"


// User project specific const
const durationOptions = [10, 15, 20, 25, 30, 35]

// DO NOT DO ANY CHNAGES - START
const MortgageCalc = props => {
  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    title,
    defaultResult,
    pricePrefix,
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
  } = props

  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [calculator_value, setCalculatorValue] = useState({ 'loan_amount': ((75 / 100) * props.calc_price).toFixed(2), 'purchase_price': props.calc_price, 'interest_rate': 3.5, 'amortization': '30', 'down_payment': ((25 / 100) * props.calc_price) });


  const prefix = pricePrefix ? currency : ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const [deposit, setDeposit] = useState(prefix + numberFormat(depositAmount))
  const [duration, setDuration] = useState(loadDuration)
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")
  const [errormsg, setErrorMsg] = useState("* All fields are required")

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(prefix + numberFormat(val))
    if (parseInt(filterNumber(deposit)) && parseInt(val)) {
      let loan = parseInt(val) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    } else {
      setLoan(prefix + 0)
    }
  }

  // const handleDeposit = event => {
  //   let d = filterNumber(event.target.value.replace(/[^\d.]/g, ""))
  //   setDeposit(prefix + numberFormat(d))

  //   if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
  //     let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
  //     setLoan(prefix + numberFormat(loan2))
  //   } else {
  //     setLoan(prefix + 0)
  //   }
  // }

  const handleDuration = event => {
    setDuration(filterNumber(event.target.value))
  }

  const handleInterest = event => {
    setCalculatorValue({ ...calculator_value, [event.target.name]: event.target.value > 99 ? 99 : numericWithDecimal(event.target.value) })



    setInterest(event.target.value.replace(/[^\d.]/g, ""))
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (interest, duration, loan) => {
    let result = -pmt(
      interest / 100 / 12,
      filterNumber(duration) * 12,
      filterNumber(loan),
      0,
      1
    )
    setResult(numberFormat(Math.round(result)))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false && parseFloat(interest) > 0) {
      setShowerror(true)
      setValidated(true)
    } else {
      let depositeAmt = parseInt(filterNumber(purchasePrice));
      if (depositeAmt > 0 && parseFloat(interest) > 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Calculator',
          'formType': title,
          'formId': "Mortgage Calculator",
          'formName': "Mortgage Calculator",
          'formLabel': "Mortgage Calculator"
        });
        setShowerror(false)
        setValidated(false)
        if (
          parseInt(filterNumber(purchasePrice)) &&
          parseInt(filterNumber(deposit))
        ) {
          let loan =
            parseInt(filterNumber(purchasePrice)) -
            parseInt(filterNumber(deposit))
          setLoan(prefix + numberFormat(loan))
          getResult(interest, duration, loan)

          // console.log('duration',parseInt(filterNumber(purchasePrice)),interest,parseInt(filterNumber(deposit)),duration)

          setMonthlyPayment(
            calculateMonthlyPayment(
              parseInt(filterNumber(purchasePrice)),
              parseFloat(interest),
              parseInt(filterNumber(deposit)),
              duration
            )
          );

          // console.log("duration1",monthlyPayment)

        }
      }
      else if (depositeAmt == 0 && parseFloat(interest) == 0) {
        setShowerror(true)
        setErrorMsg("Please enter valid borrow value and interest.")
        setResult("")
      }
      else if (parseFloat(interest) == 0) {
        setShowerror(true)
        setErrorMsg("Please enter valid interest rate.")
        setResult("")
      }
      else if (filterNumber(purchasePrice) == 0) {
        setShowerror(true)
        setErrorMsg("Please enter valid borrow value.")
        setResult("")
      }
    }
  }

  // useEffect(() => {
  //   if (
  //     parseInt(filterNumber(purchasePrice))
  //   ) {
  //     let loan =
  //       parseInt(filterNumber(purchasePrice))
  //     setLoan(prefix + numberFormat(loan))
  //   }
  //   getResult(interest, duration, loan)
  // }, [purchasePrice, deposit, loan, interest, duration, prefix])

  // DO NOT DO ANY CHNAGES - END
  var monthly_payment = Math.round(monthlyPayment);

  return (
    <div className="form mortgate-form calculator">
      {showerror && (
        <div className="alert-danger">
          <p>{errormsg}</p>
        </div>
      )}
      <Form
        name="MortgageCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />

        <Row>

          <Col md={12}>
            <Form.Group controlId="purchase_price">
              <div className="custom-float">
                <Form.Label>How much would you like to borrow?</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="purchase_price"
                  value={purchasePrice}
                  placeholder="Purchase Price"
                  onChange={handlePrice}
                />
              </div>
            </Form.Group>
          </Col>


          {/* <Col md={6}>
          <Form.Group controlId="deposit">
            <div className="custom-float">
              <Form.Label>Deposit</Form.Label>
              <Form.Control 
                required
                type="text"
                name="deposit_available"
                placeholder="Deposit"
                value={deposit}
                onChange={handleDeposit}
              />
            </div>
          </Form.Group>
        </Col> */}


          <Col md={12}>
            <Form.Group controlId="interest_rate">
              <div className="custom-float">
                <Form.Label>Interest Rate (%)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="interest_rate"
                  value={numericWithDecimal(calculator_value.interest_rate)}
                  placeholder="Interest Rate (%)"
                  onChange={handleInterest}
                  maxLength={99}
                />
              </div>
            </Form.Group>
          </Col>


          <Col md={12}>
            <Form.Group controlId="duration">
              <div className="custom-float custom-float--select">
                <Form.Label>How many years?</Form.Label>
                <Form.Control
                  as="select"
                  className="form-control form-select"
                  required
                  name="duration"
                  value={duration}
                  placeholder="Duration (Years)"
                  onChange={handleDuration}
                >
                  {durationOptions.map((value, key) => {
                    return (
                      <option value={value} key={key}>
                        {value} Years
                      </option>
                    )
                  })}
                </Form.Control>
              </div>
            </Form.Group>
          </Col>


          <Col md={12}>
            <Button className="btn btn-secondary" type="submit">
              Submit
            </Button>
          </Col>
          {result &&
            <Col md={12}>
              <div className="result">
                Monthly Payments : <span>{currency} {numberFormat(monthly_payment)}</span>
              </div>
            </Col>
          }


        </Row>
      </Form>

    </div>
  )
}

MortgageCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIT,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

MortgageCalc.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default MortgageCalc
