import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import { getIpAddress } from "../common/site/functions"
import { getAllCookie } from "../common/site/cookie";
import $ from 'jquery/dist/jquery.min.js'


// import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"

function HomeVisitForm(props) {

    const [finalresponse, setFinalResponse] = useState(false);
    // const [serverResponse, setServerResponse] = React.useState(``)

    const [ipAddress, setIpAddress] = useState("");
    const [formvalues, setFormvalues] = useState("");

    const [token, setToken] = useState("");
    const [file, setFile] = useState(""); // storing the uploaded file
    // storing the recived file from backend
    const [data, getFile] = useState({ name: "", path: "" });
    const [progress, setProgess] = useState(0); // progess bar
    const el = useRef(); // accesing input element

    const myRef = React.createRef();

    const recaptchaRef = React.createRef();
    const fields = ([
        {
            element: "config",
            formname: "Book a Valuation",
            error_text: "Highlighted fields are required | invalid",
            success_text: "Thank you for your interest. A member of our team will contact you shortly.",
            email_temp_user: "valuation_user",
            email_temp_admin: "home_visit_valuation_admin",
            email_subject_user: "Your Valuation Request",
            email_subject_admin: "Book a Valuation",
            email_server_func: "book_a_valuation",
            event_tracking: "book_a_valuation",
            page_url: "/contact"
        },
        {
            element: "captcha",
            class: "py-2 captcha-class",
            captchaRef: recaptchaRef
        }
    ]);
    const handlechange = event => {
        // remove initial empty spaces 
        event.target.value = event.target.value.trimStart()
    }



    useEffect(() => {
        getIpAddress((ip) => {
            setIpAddress(ip)
        })

        if (token !== '') {

            const processFromData = async () => {


                const page_url = typeof window !== 'undefined' ? window.location.href : ''
                formvalues['g-recaptcha-response'] = token;

                var gtm_client_id = '';
                var user_agent = '';
                if (typeof window !== 'undefined') {
                    user_agent = window.navigator.userAgent
                    if (typeof window.ga !== 'undefined') {
                        gtm_client_id = window.ga.getAll()[0].get('clientId');

                    }
                }
                let formData = new FormData();
                formvalues['name'] = formvalues.name;
                formvalues['email_subject_user'] = fields[0].email_subject_user;
                formvalues['email_subject_admin'] = fields[0].email_subject_admin;
                delete formvalues["address"]
                formvalues['referrer'] = page_url;
                formvalues['google_analytics_client_id'] = gtm_client_id;
                formvalues['user_agent'] = user_agent;
                formvalues['ip_address'] = ipAddress;
                formvalues['extra'] = JSON.stringify(formvalues);
                formvalues['address'] = {
                    "stno": props.home_stno,
                    "street": props.home_street,
                    "district": props.home_district,
                    "post_town": props.home_post_town,
                    "county": props.home_county,
                    "postcode": props.home_postcode
                }
                formData.append('data', JSON.stringify(formvalues));

                postFormData(formData).then(async apiRes => {
                    window.grecaptcha.reset()
                    // lets send mail

                    await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/functions`, {
                        method: `POST`,
                        mode: "no-cors",
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: qs.stringify(formvalues),
                    })


                    if (apiRes.success === true) {
                        setFinalResponse(true)
                        $("#track-results").hide()
                        $("#track-result-home").show()
                        $("#track-result-head").hide()
                        $("#cta-home-visit").prop('disabled', true);
                        localStorage.removeItem('buyer_name');
                        localStorage.removeItem('buyer_email');
                        localStorage.removeItem('buyer_home_stno')
                        localStorage.removeItem('buyer_home_street')
                        localStorage.removeItem('buyer_home_district')
                        localStorage.removeItem('buyer_home_post_town')
                        localStorage.removeItem('buyer_home_county')
                        localStorage.removeItem('buyer_home_postcode')
                    }
                    // .then(res => res.json())
                    // setServerResponse(response)


                    // const axiosOptions_email = {
                    //   url: '/api/server/' + fields[0].email_server_func,
                    //   method: "post",
                    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    //   data: qs.stringify(formvalues),
                    // }

                    // axios(axiosOptions_email)
                    //   .then(response => {
                    //     console.log('mail sent!')
                    //   })
                    //   .catch(err =>
                    //     console.log(err)
                    //   );
                });

                const url = typeof window !== 'undefined' ? window.location.href : ''
                if (props.title != "") {
                    fields[0].formname = props.title
                }
                // tracking event
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'formSubmit',
                    'formType': 'form-' + fields[0].event_tracking,
                    'formId': 'form-' + fields[0].event_tracking,
                    'formName': fields[0].formname,
                    'formLabel': fields[0].formname
                });

                // props.homevisit()
                // setThankyou(true);
                // setTimeout(() => {
                // $(".alert-success").show().delay(4000).fadeOut();
                // props.homevisit()
                // }, 500)
                // myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
            processFromData();

        }
    }, [token]);

    const handleonVerify = token => {
        // console.log("captcha verified");
        setToken(token);
    };

    const handleSubmit = event => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        else {
            event.preventDefault();

            const formsdata = (event.target);
            const json = {}
            Object.keys(formsdata).map(key => (
                json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
            ))

            $("#cta-home-visit").prop('disabled', true);
            $("#cta-buyer-finder-thanks").show();
            setTimeout(function () { $("#cta-buyer-finder-thanks").remove() }, 3000);

            json['email_temp_user'] = fields[0].email_temp_user;
            json['email_temp_admin'] = fields[0].email_temp_admin;
            json['formname'] = fields[0].formname;
            json['g-recaptcha-response'] = token;


            json['files'] = file;
            setFile(file);
            setFormvalues(json);

            recaptchaRef.current.execute();
            // setToken("aaa")

            // reset form
            const form = event.target
            if (finalresponse) {
                form.reset();
            }

        }
    };


    const url = typeof window !== 'undefined' ? window.location.href : ''
    var cookieData = getAllCookie();
    return (
        <>
        <Form name={fields[0].formname} className="buyer-finder-cta-form" action="/thank-you/" method="post" noValidate onSubmit={handleSubmit}>
            <input type="hidden" name="form_name" value={fields[0].formname} />
            <input type="hidden" name="address" value={props.address} />
            {/* <input type="hidden" name="type" value={props.type} /> */}
            <input type="hidden" name="name" value={props.name} />
            <input type="hidden" name="email" value={props.email} />
            <input type="hidden" name="telephone" value={props.mobile} />
            <input type="hidden" name="form_type" value={fields[0].form_type} />
            <input type="hidden" name="bot-field" />

            <input type="hidden" name="utm_source" value={cookieData.utm_source} />
            <input type="hidden" name="utm_campaign" value={cookieData.utm_campaign} />
            <input type="hidden" name="utm_term" value={cookieData.utm_term} />
            <input type="hidden" name="utm_content" value={cookieData.utm_content} />
            <input type="hidden" name="utm_medium" value={cookieData.utm_medium} />
            <input type="hidden" name="form_type_id" value={props.id} />
            <input type="hidden" name="form_page" value={url} />
            <input type="hidden" name="brand_id" value={process.env.GATSBY_STRAPI_BRAND_ID} />
            {fields.map((field, index) => {
                if ("captcha" === field.element) {
                    return (
                        <ReCaptchaBox
                            fieldClass={field.class}
                            captRef={field.captchaRef}
                            key={`${field.element}~${index}`}
                            handleonVerify={handleonVerify}
                        />
                    );
                }
            })
            }
            <button type="submit" className="btn btn-primary" id="cta-home-visit">
                <span>In-person and accurate</span>
                Home Visit Valuation</button>
        </Form>
        </>

    );
}


const BuyerFinderFormPage = (props) => (
    <HomeVisitForm classone={props.classone} {...props} homevisit={props.homevisit} to_email_id={props.to_email_id} address={props.address} type={props.type} title={props.title} name={props.name} email={props.email} mobile={props.mobile} />
)

export default BuyerFinderFormPage